<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 순회기간 -->
          <c-datepicker
            label="년도"
            type="year"
            default="today"
            name="patrolYear"
            v-model="searchParam.patrolYear"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :editable="false"
            codeGroupCd="PATROL_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            name="patrolTypeCd"
            label="점검구분"
            v-model="searchParam.patrolTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" v-if="patrolInfo.patrolTypeCd == 'PTC0000002'">
          <c-dept
            type="search"
            label="점검 대상부서"
            name="deptCd"
            v-model="searchParam.deptCd"
          />
        </div>
      </template>
    </c-search-box>
    <!-- 순회점검 목록 -->
    <c-table
      ref="table"
      :title="patrolInfo.title"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!-- 등록 -->
          <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="onItemClick" />
          <!-- 검색 -->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'patrol',
  props: {
    patrolInfo: {
      type: Object,
      default(){
        return {
          patrolTypeCd: '',
          title: '',
        }
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            // 사업장
            label: 'LBLPLANT',
            align: 'center',
            sortable: true,
            style: 'width:200px',
          },
          {
            name: 'patrolMonth',
            field: 'patrolMonth',
            // 순회일
            label: '결재 대상월',
            align: 'center',
            sortable: true,
            type: 'link',
            style: 'width:200px',
          },
          {
            name: 'approvalStatusName',
            field: 'approvalStatusName',
            // 진행단계
            label: '결재상태',
            align: 'center',
            sortable: true,
            style: 'width:200px',
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        patrolYear: null,
        patrolTypeCd: null,
        deptCd: '',
      },
      searchUrl: '',
      editable: true,
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.patrol.appr.list.url;
      this.searchParam.patrolTypeCd = this.patrolInfo.patrolTypeCd;

      
      if (this.patrolInfo.patrolTypeCd == 'PTC0000002') {
        this.grid.columns = [
          {
            name: 'plantName',
            field: 'plantName',
            // 사업장
            label: 'LBLPLANT',
            align: 'center',
            sortable: true,
            style: 'width:200px',
          },
          {
            name: 'deptName',
            field: 'deptName',
            // 진행단계
            label: '점검 대상부서',
            align: 'center',
            sortable: true,
            style: 'width:200px',
          },
          {
            name: 'patrolMonth',
            field: 'patrolMonth',
            // 순회일
            label: '결재 대상월',
            align: 'center',
            sortable: true,
            type: 'link',
            style: 'width:200px',
          },
          {
            name: 'approvalStatusName',
            field: 'approvalStatusName',
            // 진행단계
            label: '결재상태',
            align: 'center',
            sortable: true,
            style: 'width:200px',
          },
        ]
      }
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    onItemClick() {
      this.linkClick(null);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.popupOptions.title = this.patrolInfo.patrolTypeCd == 'PTC0000001' ? '협력업체점검 월별결재' : '관리감독자업무일지 월별결재'; // 순회점검 상세
      this.popupOptions.param = {
        saiPatrolMonthId: row ? row.saiPatrolMonthId : '',
        patrolTypeCd: row ? row.patrolTypeCd : this.patrolInfo.patrolTypeCd
      };
      this.popupOptions.target = () => import(`${'./patrolMonthDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
